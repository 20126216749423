export default [
    {
        path: '/clientManagement',
        name: 'ClientManagement',
        redirect: { name: 'ClientList' },
        component: () => import('../views/layout/index.vue'),
        meta: {
            title: '客户管理',
        },
        children: [
            {
                path: 'clientList',
                name: 'ClientList',
                component: () => import('../views/clientManagement/clientList/index.vue'),
                meta: {
                    title: '客户列表',
                    authority: true,
                    icon: 'User',
                },
            },
            {
                path: 'clientPool',
                name: 'ClientPool',
                component: () => import('../views/clientManagement/clientPool/index.vue'),
                meta: {
                    title: '客户池',
                    authority: true,
                    icon: 'Box',
                },
            },
        ],
    },
    {
        path: '/organizationManagement',
        name: 'OrganizationManagement',
        redirect: { name: 'ShopList' },
        component: () => import('../views/layout/index.vue'),
        meta: {
            title: '组织管理',
        },
        children: [
            {
                path: 'shopList',
                name: 'ShopList',
                component: () => import('../views/organizationManagement/shopList/index.vue'),
                meta: {
                    title: '门店列表',
                    authority: true,
                    icon: 'Shop',
                },
            },
            {
                path: 'staffList',
                name: 'StaffList',
                component: () => import('../views/organizationManagement/staffList/index.vue'),
                meta: {
                    title: '员工列表',
                    authority: true,
                    icon: 'Suitcase',
                },
            },
            {
                path: 'performance',
                name: 'Performance',
                component: () => import('../views/organizationManagement/performance/index.vue'),
                meta: {
                    title: '业绩管理',
                    authority: true,
                    icon: 'Money',
                },
            },
        ],
    },
]
