import { createRouter, createWebHistory } from 'vue-router'
import RoutsMap from './routes'

const routes = [
    {
        path: '/',
        redirect: { name: 'Login' },
        meta: {
            title: '登录',
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login/index.vue'),
        meta: {
            title: '登录',
        },
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('../views/home/index.vue'),
        meta: {
            title: '首页',
            layout: true,
        },
    },
    ...RoutsMap,
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

// 全局前置路由守卫，进入路由前对登录态以及权限进行判断
router.beforeEach(async to => {
    const token = localStorage.getItem('Authorization')
    // 进入登录页
    if (to.name === 'Login') {
        // 有登录态
        if (token) {
            const redirect = { name: 'Home' }
            return redirect
        }
        // 没有登录态
        return true
    } else {
        // 进入非登录入
        // 有登录态
        if (token) {
            return true
        } else {
            // 没有登录态 调回登录页
            return { name: 'Login' }
        }
    }
})

export default router
