import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import Aside from './aside.vue';
export default {
  __name: 'index',
  setup(__props) {
    const route = useRoute();
    const routeName = ref('');
    watch(() => route, nv => {
      routeName.value = nv.name;
    }, {
      immediate: true,
      deep: true
    });
    return (_ctx, _cache) => {
      const _component_el_aside = _resolveComponent("el-aside");
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_main = _resolveComponent("el-main");
      const _component_el_container = _resolveComponent("el-container");
      return _openBlock(), _createBlock(_component_el_container, {
        class: "pageLayout"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_container, {
          class: "pageBody"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_aside, null, {
            default: _withCtx(() => [_createVNode(Aside)]),
            _: 1
          }), _createVNode(_component_el_main, null, {
            default: _withCtx(() => [_createVNode(_component_router_view)]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      });
    };
  }
};