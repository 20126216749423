import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { setupCalendar } from 'v-calendar'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/el-message.css'
import 'element-plus/theme-chalk/el-message-box.css'
import 'element-plus/theme-chalk/el-loading.css'

const app = createApp(App)
// 注册所有element图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(setupCalendar, {}).use(store).use(router).mount('#app')
