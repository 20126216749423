import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import Layout from './views/layout/index.vue';
import { useRoute } from 'vue-router';
export default {
  __name: 'App',
  setup(__props) {
    const locale = zhCn;
    const route = useRoute();
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_unref(ElConfigProvider), {
        "z-index": 3000,
        locale: _unref(locale)
      }, {
        default: _withCtx(() => [_unref(route).meta.layout ? (_openBlock(), _createBlock(Layout, {
          key: 0
        })) : (_openBlock(), _createBlock(_component_router_view, {
          key: 1
        }))]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};